/* PASA */
.promotion-detail .checkbox{
  margin-right: 15px;
}
.titleDiv {
  background-color: #000;
  padding: 20px;
  margin-bottom: 10px;
}

.App .ui.table td.positive, .App .ui.table tr.positive {
    background: #fcffb0 !important;
    color: #2c662d !important;
}

/* CostCalculationsTable */
.ui.table.CostCalculationsTable thead th{
  font-size: 12px;
  color: rgba(0,0,0,.6);
  padding: 4px 10px;
  white-space: pre;
}
.ui.table.CostCalculationsTable tbody td{
  padding: 4px 10px;
  white-space: pre;
}

.ui.table.CostCalculationsTable tfoot tr th{
  padding: 4px 10px;
  white-space: pre;
}

.ui.table.CostCalculationsTableEdit thead th{
  font-size: 12px;
  color: rgba(0,0,0,.6);
  padding: 4px 10px;
  white-space: pre;
}
.ui.table.CostCalculationsTableEdit tbody td{
  padding: 4px 4px;
  white-space: pre;
}
.ui.table.CostCalculationsTableEdit tbody input{
  padding: 2px 2px;
  margin: -2px 0;
  min-width: 40px;
}
.ui.table.CostCalculationsTableEdit tbody .labeled.input .label{
  padding: 4px 2px;
  margin: -2px 0;
}

.ui.table.CostCalculationsTableEdit tfoot th{
  padding: 4px 4px;
  white-space: pre;
}
.ui.table.CostCalculationsTableEdit tfoot input{
  padding: 2px 2px;
  margin: -2px 0;
  min-width: 40px;
}
.ui.table.CostCalculationsTableEdit tfoot .labeled.input .label{
  padding: 4px 2px;
  margin: -2px 0;
}

.ui.table.commonTable{
  box-shadow: 0 1px 2px 0 rgba(34,36,38,.15);
}

.ui.table.commonTable thead th{
  font-size: 12px;
  color: rgba(0,0,0,.6);
  background-color: #e8e8e8;
  padding: 5px 1em;
  white-space: nowrap;
}
.ui.table.commonTable tbody td{
  padding: 5px 1em;
  white-space: nowrap;
}

.ui.table.commonTableEdit thead th{
  font-size: 12px;
  color: rgba(0,0,0,.6);
  padding: 4px 10px;
  white-space: nowrap;
}
.ui.table.commonTable tbody td{
  padding: 5px 1em;
  white-space: nowrap;
}
.ui.table.commonTable tbody input{
  padding: .67857143em 2px;
  margin: -2px 0;
  min-width: 40px;
}

/*For Datepicker*/
.react-datepicker__input-container>input {
    margin: 0;
    max-width: 100%;
    /*width: 100px;*/
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    outline: 0;
    -webkit-tap-highlight-color: rgba(255,255,255,0);
    text-align: left;
    line-height: 1.21428571em;
    font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
    padding: .67857143em 1em;
    background: #fff;
    border: 1px solid rgba(34,36,38,.15);
    color: rgba(0,0,0,.87);
    border-radius: .28571429rem;
    -webkit-transition: border-color .1s ease,-webkit-box-shadow .1s ease;
    transition: border-color .1s ease,-webkit-box-shadow .1s ease;
    -o-transition: box-shadow .1s ease,border-color .1s ease;
    transition: box-shadow .1s ease,border-color .1s ease;
    transition: box-shadow .1s ease,border-color .1s ease,-webkit-box-shadow .1s ease;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.react-datepicker__input-container>input[name="PromoFrom"] {
  width: 100px;
}

.react-datepicker__input-container>input[name="PromoThru"] {
  width: 100px;
}

.react-datepicker__input-container>input[name="PromoTermFrom"] {
  width: 196px;
}

.react-datepicker__input-container>input[name="PromoTermTo"] {
  width: 196px;
}

.react-datepicker__input-container>input[name="CheckReqDateFrom"] {
  float: right;
  width: 196px;
  height: 36px;
}

.react-datepicker__input-container>input[name="CheckReqDateTo"] {
  float: right;
  width: 196px;
  height: 36px;
}

.react-datepicker__close-icon::after{
  background-color: white!important;
  color:black!important;
  font-size:18px!important;
}

.ui.dimmer {
  z-index: 9 !important;
  background-color: rgba(100, 100, 100, 0.85) !important;
}

.react-datepicker-popper{
  z-index:10 !important;
}

.Error-Zone {
  background-color: #fff6f6 !important;
  border-color: #e0b4b4 !important;
  color: #9f3a38 !important;
}

.customDatePick {
  opacity: 0.4 !important;
  background-color: #dfdfdf !important;
}

.ui[class*="PercentageInput"].input>input {
  text-align: right;
}

.ui[class*="disabled"].input>input {
  background: #dfdfdf;
}

.ui[class*="disabled"].selection.dropdown {
  background: #dfdfdf!important;
}

.ui[class*="disabled"].checkbox .box:before, .ui[class*="disabled"].checkbox label:before{
  background: #dfdfdf!important;
}

.ui.table.requestorTable{
  box-shadow: 0 1px 2px 0 rgba(34,36,38,.15);
  margin-right: 0;
  margin-left: auto;
  margin-bottom: 0;
}

.ui.table.requestorTable thead th{
  font-size: 12px;
  color: rgba(0,0,0,.6);
  background-color: #e8e8e8;
  padding: 5px 1em;
  white-space: nowrap;
}
.ui.table.requestorTable tbody td{
  padding: 5px 1em;
  white-space: nowrap;
}

.ui.table.requestorTable tbody td{
  padding: 5px 1em;
  white-space: nowrap;
}
.Costcolc_bgcolor {
  background-color: #ffffff !important;
  border-color: #ffffff !important;
  padding: 0px !important;
}

.flex-between {
  display: flex;
  justify-content: space-between;
}

.flex {
  display: flex;
  margin-top: 10px;
}

.flex-floatRight {
  display: flex;
  flex-direction: row-reverse;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

.required-icon {
  color: red;
  font-size: 18px;
}

.calendar-icon{
  position: relative;
  top: 8px;
  right: 40px;
  width: 0!important;
}
.message{
  color :red;
  font-size: 20px;
}

.logInLabel{
  font-size: medium;
}

.descriptionFont{
  font-size: large;
}

.halfItme{
  width: 49.5%;
}

.halfWide {
  width: 50%;
}
.addButton{
  position: relative;
  display: block;
}

.style-segment-horizontal{
  display: flex;
  /* justify-content: space-between; */
}

.cursor-pointer {
  cursor:pointer;
  text-decoration:underline;
  display:block;
  padding-top:10px;
}

.cursor-pointer-document {
  cursor:pointer;
  text-decoration:underline;
}

.shipping-number {
  cursor:pointer;
  text-decoration:underline;
  display:block;
}

.gridHeight {
  overflow-y:auto;
  white-space:nowrap;
  max-height: 300px;
}

.popupWide {
  width: 600px;
}

/* For datepicker in Order List */
.react-datepicker__input-container>input[name="OrderDateFrom"] {
  width: 190px!important;
  height: 36px;
}
.react-datepicker__input-container>input[name="OrderDateTo"] {
  width: 190px!important;
  height: 36px;
}
.react-datepicker__input-container>input[name="InvoiceDateFrom"] {
  width: 190px!important;
  height: 36px;
}
.react-datepicker__input-container>input[name="InvoiceDateTo"] {
  width: 190px!important;
  height: 36px;
}
.condition{
  margin-right: 3px;
}

.masterMenu.a{
 width: 95.12px;
 text-align: center;
}
.contentWide{
 width: calc(100% - 95.12px);
 margin-top: 0!important;
 min-height: calc(100vh - 260.578px);
}

.listTable {
  table-layout: fixed;
}

.listTableCell {
  width: 8%;
}

.listTableStatus {
  width: 10%;
}

.listTablePo {
  width: 12%;
}

.listTableQty {
  width: 4%;
}

.listTableTotal {
  width: 6%;
}

.listTableShip {
  width: 18%;
}

.wordBreak {
    word-break: break-word;
}
